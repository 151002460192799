@import '../../styles/bootstrap-mixins';
@import '../../styles/vars';

.filter-header {
    width: 100%;
    height: $mobileFilterHeaderHeight;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    border-bottom: 1px solid #eaeaea;
    background: #fff;
    z-index: 2;

    @include media-breakpoint-up(md) {
        height: $filterHeaderHeight;
    }

    .container {
        align-items: center;
        flex-direction: row;
    }

    input {
        height: $mobileInputHeight;
        padding: 1rem 4.5rem 1rem 1rem;
        border-color: #dddddd;
        border-radius: 0;
        background-color: transparent;

        @include media-breakpoint-up(md) {
            height: $inputHeight;
            padding: 1rem 7rem 1rem 32rem;
        }

        @include media-breakpoint-up(lg) {
            padding: 1rem 12rem 1rem 32rem;
        }
    }

    .placeholder {
        height: $mobileInputHeight;
        display: flex;
        align-items: center;
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 1rem 4.5rem 1rem 1rem;
        color: #777;
        font-size: 1.2rem;
        line-height: 1.2;
        z-index: -1;

        @include media-breakpoint-up(md) {
            height: $inputHeight;
            top: 0;
            bottom: auto;
            padding: 1rem 7rem 1rem 32rem;
            font-size: 1.5rem;
            line-height: 1.5;
        }

        @include media-breakpoint-up(lg) {
            padding: 1rem 12rem 1rem 32rem;
        }
    }

    .custom-select {
        height: $mobileInputHeight;
        margin: 0 0 1.6rem;

        @include media-breakpoint-up(md) {
            height: calc(#{$inputHeight} - 2px);
            position: absolute;
            top: 1px;
            left: 2px;
            border: none;
        }

        .select-control {
            &__indicator-separator {
                background-color: #444444;

                @include media-breakpoint-up(md) {
                    height: calc(100% - 16px);
                    position: absolute;
                    right: 0;
                    top: 8px;
                    margin: 0;
                }
            }

            &__indicator {
                height: 100%;

                svg {
                    height: auto;
                    fill: #444444;
                }

                @include media-breakpoint-up(md) {
                    padding: 8px 16px;
                }
            }
        }
    }

    .btn-search {
        width: 4rem;
        height: $mobileInputHeight;
        position: absolute;
        bottom: 0;
        right: 0;
        border: none;
        border-radius: 0;
        background-color: $primaryColor;
        color: #ffffff;
        font-size: 0;

        svg {
            width: 2.2rem;
            height: auto;
            fill: #ffffff;
        }

        @include media-breakpoint-up(md) {
            width: 6rem;
            height: $inputHeight;
            top: 0;
            bottom: auto;
        }

        @include media-breakpoint-up(lg) {
            width: 10rem;
            font-size: 1.5rem;

            svg {
                display: none;
            }
        }
    }
}