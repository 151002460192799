@import '../../styles/bootstrap-mixins';
@import '../../styles/vars';

.sorting {
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
    padding-left: 1.2rem;
    border: 1px solid #AAB2BD;

    @include media-breakpoint-down(sm) {
        width: 100%;
        margin: 0 0 2.4rem;
        justify-content: space-between;
    }

    label {
        margin: 0 0 0;
        font-size: 1.5rem;
    }

    .custom-select {
        width: 14rem;
        border: none;

        @include media-breakpoint-down(sm) {
            width: 80%;
        }
    }

}

.aside-filter {
    h4 {
        display: flex;
        justify-content: space-between;
        margin: 0 0 2.4rem;
        font-weight: 500;
        cursor: default;

        svg {
            width: 2.5rem;
            height: 2.5rem;
        }
    }

    hr {
        margin: 3rem 0;
    }

    &__date-pickers {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding: 0 1.2rem;
        border: 1px solid #AAB2BD;
        color: #656D78;

        svg {
            width: 2rem;
            height: 2rem;
            fill: #656D78;
        }

        .dates {
            width: 50%;

            .date-picker {
                width: 100%;
                border: none;
                padding: 0.8rem 1rem;
                background-color: transparent;
                font-size: 1.4rem;
            }
        }
    }

    &__list {
        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            margin-bottom: 1.6rem;

            p {
                width: 100%;
            }

            span {
                width: calc(100% - 2rem);
                display: flex;
                align-items: flex-start;
                font-size: 1.55rem;
                font-weight: 500;

                >span {
                    width: calc(100% - 5rem);
                }

                svg {
                    width: 2.5rem;
                    height: 2.5rem;
                    margin-right: 1.3rem;
                    fill: #5d5f61;
                }

                .legend-offers,
                .legend-requests {
                    width: 2rem;
                    height: 2rem;
                    margin-right: 1.7rem;
                    border-radius: 50%;
                }

                .legend-offers {
                    background-color: $primaryColor;
                }

                .legend-requests {
                    background-color: $secondaryColor;
                }
            }
        }

        &--info {
            padding: 2rem;
            border: 1px solid #ececec;
            background-color: #F5F7FA;

            li {
                flex-flow: row wrap;
            }

            span {
                width: auto;
                margin: 0 0 0.8rem;
                font-size: 1.4rem;

                svg {
                    margin-right: 0.8rem !important;
                }
            }

            p {
                span {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: relative;
                    margin: 0 0 0.3rem;
                    font-size: 1.35rem;
                    font-weight: 600;
                    line-height: 1.5;
                }
            }

            .offer,
            .request {
                width: 100%;

                span {
                    width: 3rem;
                    height: 3rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 1rem;
                    border-radius: 50%;
                    color: #ffffff;
                    font-weight: 600;
                    font-size: 1.2rem;
                    line-height: 1.1;
                }
            }

            .offer {
                color: $primaryColor;

                span {
                    background-color: $primaryColor;
                }
            }

            .request {
                color: $secondaryColor;

                span {
                    background-color: $secondaryColor;
                }
            }
        }
    }

    .profile-informations {
        svg {
            width: 2.5rem;
            height: 2.5rem;
        }
    }
}

.search-results {
    padding-top: $filterHeaderHeight + 4rem;

    @include media-breakpoint-down(md) {
        padding-top: $mobileFilterHeaderHeight + 2rem;
    }

    .results-found {
        margin: 0 0 3.5rem;
        color: #777777;
        font-weight: 500;
    }

    &__group {
        margin-bottom: 3.5rem;

        @include media-breakpoint-down(md) {
            margin-bottom: 2rem;
        }

        h4 {
            font-weight: 500;
        }

        .btn-view-more {
            width: 18rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 auto;
            padding: 0;
            border: none;
            background-color: transparent;
            color: $secondaryColor;
            font-size: 1.4rem;
            font-weight: 600;
            text-align: center;

            img {
                width: 20px;
            }
        }
    }
}

.profile-card-view {
    display: flex;
    flex-direction: column;
    padding: 2.4rem;
    box-shadow: 0 4px 7px 0px rgb(226, 226, 226);
    border: 1px solid #ececec;
    background-color: #ffffff;

    @include media-breakpoint-down(md) {
        padding: 1.8rem;
        box-shadow: 0 2px 3px 0px #e2e2e2;
    }

    h4 {
        font-weight: 500;
        cursor: pointer;

        &:hover {
            color: $primaryColor;
        }
    }

    &__subtitle {
        margin: 0 0 1.8rem;

        @include media-breakpoint-down(md) {
            align-items: center;
            margin: 0 0 0.8rem;
        }

        h6 {
            display: flex;
            align-items: center;
            flex-flow: row wrap;
            margin: 0;
            font-family: $defaultFontFamily;
            font-weight: 500;

            span {
                svg {
                    width: 2.5rem;
                    height: 2.5rem;
                    margin-right: 0.5rem;

                    @include media-breakpoint-down(md) {
                        width: 2rem;
                        height: 2rem;
                    }
                }

                @include media-breakpoint-down(md) {
                    margin-bottom: 0.5rem;
                }
            }
        }

        .country {
            margin-left: 0.5rem;
            margin-right: 0.5rem;

            .flag {
                width: 2rem;
                height: 1.5rem;
                margin-right: 0.8rem;
            }
        }

        .requests,
        .offers {
            margin-left: auto;
            padding: 0.3rem 0.8rem;
            color: #ffffff;

            &:not(:last-child) {
                margin-right: 1rem;
            }

            @include media-breakpoint-down(md) {
                margin-left: 0.5rem;
                font-size: 1.1rem;
            }
        }

        .requests {
            background-color: $secondaryColor;
        }

        .offers {
            background-color: $primaryColor;
        }
    }

    p {
        color: #777777;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}

.card-detail {
    display: flex;
    flex-direction: column;
    padding-top: $filterHeaderHeight + 4rem;
    padding-bottom: 5rem;

    .title {
        margin: 3rem 0 2rem;
        color: #555555;
        font-weight: 500;

        small {
            display: block;
            color: $color-2;
            font-size: 1.6rem;
            font-weight: 600;
        }
    }

    .key-info {
        display: block;
        color: #323133;
        font-size: 1.5rem;
        font-weight: 600;
    }

    .profile-key-info {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 2rem;

        @include media-breakpoint-up(md) {
            margin: 0 0 6rem;
        }

        span {
            display: flex;
            align-items: center;
            margin: 0 0.8rem 0.8rem 0;
            padding: 0.3rem 1rem;
            border: 1px solid #226573;
            border-radius: 3px;
            color: #226573;

            @include media-breakpoint-up(md) {
                margin: 0 1.8rem 0.8rem 0;
            }

            >span {
                margin: 0 1rem 0 0;
                padding: 0;
                border: none;
                font-size: 2.8rem;
                line-height: 1;
            }

            svg {
                width: 2.5rem;
                height: auto;
                margin-right: 1rem;
                fill: #226573;
            }

            &.requests {
                border-color: transparent;
                color: #ffffff;
                background-color: $secondaryColor;
            }

            &.offers {
                color: #ffffff;
                border-color: transparent;
                background-color: $primaryColor;
            }
        }
    }

    .btn-back {
        margin: 0 0 0 auto;
        padding: 0.8rem 1.5rem;
        border: 1px solid #444444;
        border-radius: $borderRadius;
        background-color: transparent;
        color: #444444;
        text-align: center;
    }

    .btn-default {
        height: 3.2rem;
        padding: 0.5rem 1.2rem;
    }

    .card {
        margin-bottom: 3rem;
        padding: 4rem 2.4rem;
        box-shadow: 0 0px 5px 0px #e2e2e2;
        border: none;
        background-color: #ffffff;

        h3 {
            margin-bottom: 2.4rem;
            color: $color-1;
            font-weight: 500;
        }

        h4 {
            margin: 0 0 1rem 0;
            color: #555555;
            font-size: 1.8rem;
            font-weight: 500;
            line-height: 1.5;
        }

        .card-lists {
            li {

                p {
                    font-size: 1.5rem;
                    line-height: 1.8;

                    span {
                        display: inline-block;
                        margin: 0 0.5rem 0.5rem 0;
                        padding: 0.3rem 0.5rem;
                        border-radius: 3px;
                        background-color: $inputBorder;
                    }

                    a {
                        color: $inputBorder;
                    }
                }

                &:not(:last-child) {
                    margin-bottom: 1.8rem;
                    padding-bottom: 1.8rem;
                    border-bottom: 1px solid #E6E9ED;
                }
            }

            &--inline {
                li {
                    display: flex;
                    flex-flow: wrap;

                    h4 {
                        width: 100%;
                        margin: 0 3% 0 0;

                        @include media-breakpoint-up(md) {
                            width: 35%;
                        }
                    }

                    p {
                        width: 100%;

                        &:last-child {
                            margin: 0;
                        }

                        @include media-breakpoint-up(md) {
                            width: 62%;
                        }
                    }
                }
            }
        }

        &#key-info {
            margin-top: 8rem;
        }
    }
}

.sticky-nav-link {
    width: 100%;
    position: absolute;
    left: 0;
    top: 36rem;
    background-color: #ffffff;
    z-index: 1;
    transform: translateY(12rem);

    @include media-breakpoint-up(md) {
        transform: translateY(18rem);
    }

    &__list {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        transition: 300ms ease;

        @include media-breakpoint-down(sm) {
            max-height: 5rem;
            height: auto;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            &.open {
                max-height: 100rem;
                transition: 300ms ease;
            }
        }
    }

    button {
        min-height: 5rem;
        height: 100%;
        margin: 0 1rem;
        padding: 0;
        border: none;
        border-bottom: 3px solid transparent;
        background-color: transparent;
        color: #555555;
        font-size: 1.6rem;
        font-weight: 600;
        text-align: center;

        &.active {
            color: #4AA6B9;
            border-color: #4AA6B9;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
            margin: 0;
            padding: 1rem 1.5rem;
            border-bottom: 1px solid #eeeeee;

            &.active {
                border-bottom: 3px solid #4AA6B9;
                order: -1;
            }
        }
    }

    &__toggle {
        height: 5rem !important;
        display: none;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        color: #4AA6B9;
        text-align: center;

        svg {
            width: 2.5rem;
            margin-left: auto;
        }

        &.open {
            z-index: -1;
        }

        @include media-breakpoint-down(sm) {
            display: flex;
        }
    }

    &.sticky {
        position: fixed;
        box-shadow: 0 3px 8px -6px rgba(0, 0, 0, 0.3);
        transform: translateY(0);
    }
}