@import './vars';
@import '../styles/bootstrap-mixins';

@font-face {
  font-family: 'Maven Pro';
  src: url('../assets/fonts/maven-pro/MavenPro-Regular.woff2') format('woff2'),
    url('../assets/fonts/maven-pro/MavenPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Maven Pro';
  src: url('../assets/fonts/maven-pro/MavenPro-Medium.woff2') format('woff2'),
    url('../assets/fonts/maven-pro/MavenPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Maven Pro';
  src: url('../assets/fonts/maven-pro/MavenPro-SemiBold.woff2') format('woff2'),
    url('../assets/fonts/maven-pro/MavenPro-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Maven Pro';
  src: url('../assets/fonts/maven-pro/MavenPro-Bold.woff2') format('woff2'),
    url('../assets/fonts/maven-pro/MavenPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/roboto/Roboto-Thin.woff2') format('woff2'),
    url('../assets/fonts/roboto/Roboto-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/roboto/Roboto-Light.woff2') format('woff2'),
    url('../assets/fonts/roboto/Roboto-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/roboto/Roboto-Regular.woff2') format('woff2'),
    url('../assets/fonts/roboto/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/roboto/Roboto-Medium.woff2') format('woff2'),
    url('../assets/fonts/roboto/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/roboto/Roboto-Bold.woff2') format('woff2'),
    url('../assets/fonts/roboto/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}


:root {
  font-size: 9px;
}

@media screen and (min-width: 1280px) {
  :root {
    font-size: 7.8px;
  }
}

@media screen and (min-width: 1300px) {
  :root {
    font-size: 10px;
  }
}

html,
body {
  font-family: $defaultFontFamily;
  font-size: $default-font-size;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // text-rendering: optimizeLegibility;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 1.5rem;
  font-family: 'Maven Pro';
  font-weight: 700;

  @include media-breakpoint-down(md) {
    margin: 0 0 1rem;
  }
}

h1 {
  font-size: 3.318rem;
  line-height: 4rem;
}

h2 {
  font-size: 2.765rem;
  line-height: 3.4rem;
}

h3 {
  font-size: 2.304rem;
  line-height: 2.8rem;
}

h4 {
  font-size: 1.92rem;
  line-height: 2.3rem;

  @include media-breakpoint-down(md) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

h5 {
  font-size: 1.6rem;
  line-height: 1.9rem;
}

h6 {
  font-size: 1.4rem;
  line-height: 1.6rem;

  @include media-breakpoint-down(md) {
    font-size: 1.3rem;
    line-height: 1.3rem;
  }
}