$primaryColor: #87BA8F;
$secondaryColor: #C8007F;
$errorColor: #E74E10;
$successColor: #8CC152;

$color-1: #4AA6B9;
$color-2: #E74E10;

$pageBg:#ffffff;
$default-txt-color: #323133;

$inputHeight: 55px;
$mobileInputHeight: 35px;
$inputBorder: #82c5c7;

$borderRadius: 4px;

$defaultFontFamily: 'Roboto';
$default-font-size: 1.5rem;

$filterHeaderHeight: 10rem;
$mobileFilterHeaderHeight: 12rem;