@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import './styles/bootstrap-mixins';
@import './styles/vars';
@import './styles/typography';

html,
body {
    max-width: 100%;
    max-height: 100%;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;
    font-weight: 400;
    background-color: $pageBg;
    color: $default-txt-color;
    line-height: 1.5;
}

* {
    box-sizing: border-box;
}

/* ======== Start Common Styles ======== */
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

figure {
    margin: 0;
}

a,
button {
    color: $default-txt-color;
    font-size: 1.4rem;
    outline: none;
    text-decoration: none;

    &:focus,
    &:hover {
        color: $primaryColor;
        outline: none;
        text-decoration: none;
    }
}

p {
    margin: 0 0 1rem;
    line-height: 2.4rem;
}

.pointer-event-none {
    pointer-events: none;
}

input,
select,
textarea,
.form-control {
    font-size: $default-font-size;

    &:focus {
        border-color: $primaryColor;
        box-shadow: none;
        outline: none;
    }
}

textarea {
    resize: none;
}

/* ======== End Common Styles ======== */

/* ======== Start Button Styles ======== */
.btn-default {
    height: $inputHeight;
    padding: 1rem 2rem;
    border: 1px solid transparent;
    background: $primaryColor;
    color: #ffffff;
    text-align: center;
    transition: 300ms ease;

    &:hover {
        border-color: $primaryColor;
        background: transparent;
    }

    &:focus {
        background: $primaryColor;
        color: #ffffff;
    }
}

/* ======== End Button Styles ======== */

/* ======== Start Custom Select Styles ======== */
.custom-select {
    height: calc(#{$mobileInputHeight} - 2px);
    padding: 0;
    border: 1px solid #AAB2BD;
    font-size: $default-font-size;

    @include media-breakpoint-up(md) {
        width: 30rem;
        height: 35px;
    }

    .select-control {
        &__control {
            min-height: initial;
            height: 100%;
            border: none;
            border-radius: 0;

            input {
                height: auto;
            }

            &--is-focused {
                border: none;
                box-shadow: none;
            }
        }

        &__multi-value {
            background-color: #888888;
            color: #ffffff;

            >div {
                width: auto !important;
            }

            &__label {
                color: #ffffff;
                font-size: 1.11rem;
            }
        }

        &__value-container {
            height: 100%;
            position: static;
            padding: 0 0 0 1.2rem;
            overflow: auto;
            box-sizing: content-box;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
}

/* ======== End Custom Select Styles ======== */

/* ======== Start Default Tab Styles ======== */
.default-tab {
    .nav-tabs {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;

        li {
            margin: 0;

            a {
                padding: 1rem 2rem;
                border: none;
                border-bottom: 2px solid transparent;
                font-size: 1.5rem;
                font-weight: 500;
                cursor: pointer;

                &.active {
                    border-bottom: 2px solid $color-2;
                    background-color: transparent;
                    color: $color-2;

                    &:hover {
                        border-color: $color-2;
                    }
                }

                &:hover {
                    border-color: transparent;
                    color: $color-2;
                }
            }
        }
    }

    .tab-pane {
        padding: 2.4rem;
        border-radius: $borderRadius;
        background: #fff;
        box-shadow: 0px 2px 2px #E6E9ED;

        h3 {
            margin-bottom: 3rem;
            color: $color-2;
            font-weight: 600;
        }
    }
}

/* ======== End Default Tab Styles ======== */

/* ======== Start Custom Check Box Styles ======== */
.checkbox {
    --background: #fff;
    --border: #D1D6EE;
    --border-hover: #BBC1E1;
    --border-active: #1E2235;
    --tick: #fff;
    position: relative;

    input,
    svg {
        width: 21px;
        height: 21px;
        display: block;
    }

    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        position: relative;
        outline: none;
        background: var(--background);
        border: none;
        margin: 0;
        padding: 0;
        cursor: pointer;
        border-radius: 4px;
        transition: box-shadow .3s;
        box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));

        &:hover {
            --s: 2px;
            --b: var(--border-hover);
        }

        &:checked {
            --b: var(--border-active);
        }
    }

    svg {
        pointer-events: none;
        fill: none;
        stroke-width: 2px;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke: var(--stroke, var(--border-active));
        position: absolute;
        top: 0;
        left: 0;
        width: 21px;
        height: 21px;
        transform: scale(var(--scale, 1)) translateZ(0);
    }

    &.bounce {
        --stroke: var(--tick);

        input {
            &:checked {
                --s: 11px;

                &+svg {
                    animation: bounce .4s linear forwards .2s;
                }
            }
        }

        svg {
            --scale: 0;
        }
    }
}

@keyframes bounce {
    50% {
        transform: scale(1.2);
    }

    75% {
        transform: scale(.9);
    }

    100% {
        transform: scale(1);
    }
}

/* ======== End Custom Check Box Styles ======== */

/* ======== Start Default React DatePick Styles ======== */
.react-datepicker-popper {
    top: 0 !important;
    z-index: 9 !important;

    .react-datepicker {
        border: none;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, .3);
        font-family: $defaultFontFamily;
        font-size: $default-font-size;

        &__navigation {
            width: 15px;
            height: 12px;
            border-width: 0.8rem;
        }

        &__header {
            background-color: #656D78;
        }

        &__current-month {
            color: #fff;
            font-size: 1.4rem;
        }

        &__day-name,
        &__day,
        &__time-name {
            width: 2.2rem;
            margin: 0;
            padding: 0.8rem;
            border-radius: 0;
            box-sizing: content-box;
            line-height: 2.2rem;
            outline: none;
        }

        &__day {
            transition: 300ms ease;

            &--selected,
            &--keyboard-selected {
                background-color: $primaryColor;
            }

            &:hover {
                border-radius: 0;
                background-color: rgba($color: $color-1, $alpha: 0.5);
                color: #ffffff;
            }
        }


        &__day-name {
            color: #ffffff;
        }
    }
}

/* ======== End Default React DatePick Styles ======== */

/* ======== Start Pagination Styles ======== */
.pagination-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2.4rem 0 5rem;

    @include media-breakpoint-down(sm) {
        flex-direction: column;
    }

    .page-count {
        margin: 0;
        font-weight: 400;

        @include media-breakpoint-down(sm) {
            margin: 0 0 1.5rem;
        }
    }

    .pagination-custom {
        display: flex;
        align-items: center;

        .page-item {
            .page-link {
                width: 3.4rem;
                height: 3.4rem;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 0.2rem;
                padding: 0;
                border: none;
                border-radius: 50%;
                background-color: transparent;
                color: #777777;
                font-size: 1.5rem;
                font-weight: 500;
                overflow: hidden;
                line-height: 1.5;
                cursor: pointer;

                svg {
                    width: 1.6rem;
                    height: auto;
                    fill: #777777;
                }

                &.first,
                &.last {
                    width: auto;
                }

                &:hover {
                    color: $color-1;
                    fill: $color-1;
                }
            }

            &.disabled {
                color: #cccccc;

                .page-link {
                    svg {
                        fill: #cccccc;
                    }
                }
            }

            &.active {
                .page-link {
                    background-color: #e6e6e6;
                    color: $color-1;
                }
            }
        }
    }
}

/* ======== End Pagination Styles ======== */

.loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.1);
    z-index: 9;
}

.spin {
    display: inline-block;
    width: 80px;
    height: 80px;

    &:after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid $color-1;
        border-color: $color-1 transparent $color-1 transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}